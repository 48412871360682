import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, colors, radius, lineHeights } from 'theme'
import { CONTAINER_MAX_WIDTH, FREQUENTLY_ASKED_QUESTIONS, LISTENING } from 'consts'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import GetStartedComponent from 'components/GetStartedComponent'
import G2FeaturesComponent from 'components/G2FeaturesComponent'

import imageCoreFeatures from 'static/images/listening/core_feature.webp'
import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'

import GridDropdown from '../Faq/GridDropdown'
import SelectPlanButton from '../Home/components/SelectPlanButton'
import HeaderImageAnimation from './HeaderImageAnimation'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const FEATURE_ITEMS = [
  {
    title: 'Implement effortlessly',
    description:
      'Incorporate social media listening and monitoring seamlessly into your business strategy with Vista Social’s simple-to-use platform. Improve decision making through valuable insight on trending discussion topics.',
  },
  {
    title: 'Find valuable insights',
    description:
      'Access and utilize conversation topic insights. Track and analyze conversations around relevant topics to understand consumer sentiment and brand health.',
  },
  {
    title: 'Improve with audiences',
    description:
      'Keep up with trends while identifying influencers and thought leaders to engage as brand advocates. Gather honest feedback to generate new product, service and content ideas.',
  },
  {
    title: 'Stay a step ahead',
    description:
      'Own share of voice and distinguish your brand. Discover how customers feel about your brand, your competitors, identify industry gaps and leverage opportunities to differentiate your business.',
  },
  {
    title: 'Evolve your business strategy',
    description:
      'Understand and apply insights to your strategory. Revamp marketing, customer service, product development and much more using listening data.',
  },
]

const Listening = () => {
  return (
    <App fullHeader>
      <SEO
        title="Uncover business-critical insights with social listening"
        description="Vista Social Listening tools help brands monitor social conversation to extract actionable insights, identify industry gaps and improve brand health."
        path="/social-media-listening/"
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              mb={{ mobile: 0, desktop: 'xl' }}
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column">
                  <Flex width="100%" justifyContent="center" flexDirection="column">
                    <Flex flexDirection={{ mobile: 'row', desktop: 'column' }} flexWrap="wrap" justifyContent="center">
                      <H1
                        fontSize="4xl"
                        fontWeight="bold"
                        color={COLOR_CONSTANTS.DENIM}
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        Uncover business-critical insights with{' '}
                        <H1
                          fontSize="4xl"
                          fontWeight="bold"
                          color="primary"
                          textAlign={{ mobile: 'center', desktop: 'left' }}
                        >
                          Social Listening
                        </H1>
                      </H1>
                    </Flex>
                    <H2
                      mt="m"
                      pt="s"
                      color="secondaryText"
                      fontSize="m"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                      lineHeight={lineHeights.xxl}
                    >
                      Vista Social Listening tools help brands monitor social conversation to extract actionable
                      insights, identify industry gaps and improve brand health.
                    </H2>
                    <SelectPlanButton />
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }}>
                <HeaderImageAnimation />
              </Flex>
            </Grid>

            <Flex mt="m" pt="m" flexDirection="column" alignItems="center" mb={{ mobile: 'l', desktop: 'l' }}>
              <G2FeaturesComponent />
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            What’s included:{' '}
            <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Core Features
            </H1>
          </H3>
          <Flex mt="xl" justifyContent={{ mobile: 'center', desktop: 'space-between' }}>
            <LocalImage
              src={imageCoreFeatures}
              display={{ mobile: 'none', tablet: 'block' }}
              maxWidth="318px"
              alt="Core Features"
            />
            <Grid gridGap="l" gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', desktop: 'repeat(2, 1fr)' }}>
              {FEATURE_ITEMS.map(({ title, description }) => (
                <Flex flexDirection="column" key={title} maxWidth="350px" width="100%">
                  <Flex alignItems="center">
                    <Box borderRadius={radius.pill} width="8px" height="8px" minWidth="8px" bg={colors.primary} />
                    <Text ml="s" color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl">
                      {title}
                    </Text>
                  </Flex>
                  <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
                    {description}
                  </Text>
                </Flex>
              ))}
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <GetStartedComponent activeKey={LISTENING} />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Frequently Asked{' '}
            <H3 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Questions
            </H3>
          </H3>
          <Flex flexDirection="column" mt="xl" width="100%">
            <GridDropdown faqs={FREQUENTLY_ASKED_QUESTIONS.all.slice(0, 4)} />
            <Flex mt="l" justifyContent="flex-end">
              <StyledLinkText as="a" href="/faq" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                  ALL OUR FAQS
                </Text>
                <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
              </StyledLinkText>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Footer />
    </App>
  )
}

export default Listening
